<template>
    <div>
        <CompModal ref="comp_modal" title="商户号开通情况" isFull :onSuccess="onSubmit">
            <comp-table ref="comp_table" :columns="columns" :isToolbar="false" :table-api="tableApi">
                <template #search="data">
                    <Input v-model.trim="data.search.name" placeholder="店铺名称" style="width: 160px"></Input>
                </template>
            </comp-table>
        </CompModal>

        <!-- 商户申请资料 -->
        <ApplyData ref="applyData"></ApplyData>
    </div>
</template>

<script>
import CompTable from "../../components/comp-table.vue"
import CompModal from "../../../residentdatabase/components/CompModal.vue"
import ApplyData from "./applyData.vue"

export default {
    components: { CompTable, CompModal, ApplyData },

    data() {
        return {
            // 表格 API 地址
            tableApi: null,

            columns: [
                {
                    title: "店铺名称",
                    key: "name",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "店铺ID",
                    minWidth: 100,
                    align: "center",
                    key: "shopId",
                },
                {
                    title: "商户开通状态",
                    key: "openStatus",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "入驻时间",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.startDate ? this.$core.formatDate(new Date(params.row.startDate), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "激活时间",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.activeDate ? this.$core.formatDate(new Date(params.row.activeDate), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "资料提交时间",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.submitDate ? this.$core.formatDate(new Date(params.row.submitDate), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "开通完成时间",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.completionDate ? this.$core.formatDate(new Date(params.row.completionDate), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "资料提交账号",
                    key: "submitter",
                    minWidth: 280,
                    align: "center",
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 230,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "primary",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.applyData.display(params.row.companyId)
                                        },
                                    },
                                },
                                "查看商户申请资料"
                            ),
                        ])
                    },
                },
            ],
        }
    },

    methods: {
        display(id) {
            if (!this.tableApi) {
                this.tableApi = `/gateway/api/sycompany/pc/company/listOpeningSituation`
            } else {
                this.$refs.comp_table.refresh()
            }
            this.$refs.comp_modal.display()
        },

        onReset() {
            this.$refs.comp_table.refresh()
        },

        /**
         * 新增机构
         */
        onDisplayAdd() {
            this.$refs.form.display()
        },

        onSubmitBefore(data) {
            data.supplierId = this.supplierId
            return data
        },
    },
}
</script>
