<template>
    <CompForm
        ref="comp_form"
        title="商户申请资料"
        id-key="companyId"
        detailApi="/gateway/api/sycompany/pc/company/getCompanyManageInfo"
        :forms="structure"
        fullscreen
        :dataBefore="onDataBefore"
        submitApi="/gateway/api/sycompany/pc/company/saveOperatorCharge"
        :submitBefore="onSubmitBefore"
    ></CompForm>
</template>

<script>
import Request from "../../utils/request"
import CompForm from "../../components/CompForm.vue"

export default {
    components: {
        CompForm,
    },

    data() {
        return {
            structure: [
                {
                    type: "text",
                    title: "超级管理员信息",
                },
                {
                    type: "input",
                    title: "超管姓名",
                    name: "name",
                    required: true,
                },
                {
                    type: "input",
                    title: "身份证号",
                    name: "idNum",
                    required: true,
                },
                {
                    type: "input",
                    title: "手机号码",
                    name: "mobile",
                    required: true,
                },
                {
                    type: "input",
                    title: "邮箱",
                    name: "email",
                    required: true,
                },
                {
                    type: "text",
                    title: "所需法人/经营者资料信息",
                },
                {
                    type: "upload",
                    title: "身份证正面",
                    name: "idCardFront",
                    resource_type: "image",
                    required: true,
                },
                {
                    type: "upload",
                    title: "身份证反面",
                    name: "idCardBack",
                    resource_type: "image",
                    required: true,
                },
                {
                    type: "upload",
                    title: "营业执照",
                    name: "businessLicense",
                    resource_type: "image",
                    required: true,
                },

                {
                    type: "upload",
                    title: "开户许可证",
                    name: "bankLicence",
                    resource_type: "image",
                    required: true,
                },
                {
                    type: "upload",
                    title: "门头照",
                    name: "facadePhoto",
                    resource_type: "image",
                    required: true,
                },
                {
                    type: "upload",
                    title: "室内场景照",
                    name: "doorScenePhoto",
                    resource_type: "image",
                    required: true,
                },


                {
                    type: "text",
                    title: "结算银行账户",
                },
                {
                    type: "input",
                    title: "银行开户名",
                    name: "bankAdminName",
                    required: true,
                },
                {
                    type: "input",
                    title: "银行账号",
                    name: "bankAccount",
                    required: true,
                },
                {
                    type: "input",
                    title: "开户支行名称",
                    name: "bankBranchName",
                    required: true,
                },
            ],
        }
    },

    methods: {
        display(id) {
            this.companyId = id
            this.$refs.comp_form.display(id)
        },

        onSubmitBefore(data) {
            data.submitter = parent.vue.loginInfo.userinfo.custGlobalId
        },
    },
}
</script>
